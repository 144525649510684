interface ItrackEventParams {
  /** 事件id */
  eventid: number
  /** 事件类别 */
  category: string
  /** 事件动作 */
  action: string
  /** 标签/事件名称 */
  label: string
  /** 值 */
  value: Record<string, any>
  [key: string]: any
}

/**
 * 获取 urlquery参数
 */
export function getUrlQuery() {
  const queryString = location.search

  if (!queryString) { return {} }

  const arr = queryString.split('?')[1].split('&')

  const result: { [key: string]: any } = {}

  arr.forEach((item) => {
    const value = item.split('=')
    result[value[0]] = value[1]
  })

  return result
}

export function trackEvent(params: ItrackEventParams) {
  if (import.meta.env.SSR) { return }

  try {
    const query = getUrlQuery()

    const refid = query.refid || undefined

    const trackParams = {
      ...params,
      value: JSON.stringify({ ...params.value, refid }),
    }

    console.log('埋点信息:', JSON.stringify(trackParams))
    window.collectEvent(params.eventid, trackParams)
  }
  catch (error) {
    console.log(error)
  }
}
